/** @jsx jsx */
import { jsx, Grid, Button, Flex, Text } from 'theme-ui'
import Layout from '../components/layout'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import { responsive } from '../utils/helpers'
import { onClickGtag } from '../utils/gtag'
import { ImageCard } from '../components/card'
import { HeroLayout, HeroTitle } from '../components/hero'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { ShirtPromo } from '../components/promo'
import ShopIcon from '../images/shop.png'
import { useStaticQuery, graphql } from 'gatsby'
import MetaData from '../components/metadata'

const Shop = props => {
  const isDesktop = useMediaQuery({ query: '(min-width: 961px)' })

  const contentSX = {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }

  const iconSX = {
    width: '277px',
    height: 'auto',
    position: 'relative',
    left: responsive('0px', '-30px'),
  }

  const textSX = {
    color: 'white',
    fontSize: responsive(4, 5),
    margin: '10px 0 20px',
    lineHeight: 1.3,
  }

  const imageSX = {
    width: '230px',
    margin: '0 auto',
  }

  const query = useStaticQuery(graphql`
    query ShopQuery {
      contentfulShop {
        collections {
          title
          slug
          bannerText {
            bannerText
          }
          isProduct
          isPreOrder
          hero {
            file {
              url
            }
            gatsbyImageData(layout: FIXED, width: 1700, height: 580)
          }
          thumbnail {
            file {
              url
            }
          }
          products
        }
      }
      allShopifyProductVariant(filter: { product: { id: { ne: null } } }) {
        nodes {
          shopifyId: storefrontId
          image {
            originalSrc
          }
        }
      }
    }
  `)

  const collections = query?.contentfulShop?.collections
  const products = query?.allShopifyProductVariant?.nodes

  const ShopHero = () => {
    if (!collections) return null

    const heroCollection = collections[0]
    let product

    if (heroCollection.isProduct && products) {
      product = products.find(
        product => product.shopifyId === heroCollection.products[0]
      )
    }

    return (
      <HeroLayout
        variant="center"
        background={heroCollection.hero?.file?.url}
        gatsbyBgImg={heroCollection.hero?.gatsbyImageData}
        height={heroCollection.bannerText ? '635px' : undefined}
        product={heroCollection.isProduct}
        isDesktop={isDesktop}
      >
        <Grid
          columns={responsive(1, '1fr 320px')}
          gap={'30%'}
          sx={{ width: '100%' }}
        >
          <Flex sx={contentSX}>
            <img src={ShopIcon} alt="FGR Shop" sx={iconSX} />
            <HeroTitle variant="large">{heroCollection.title}</HeroTitle>
            {heroCollection.bannerText && heroCollection.bannerText.bannerText && (
              <Text as="p" sx={textSX}>
                {heroCollection.bannerText.bannerText}
              </Text>
            )}
            <Button
              as={Link}
              to={
                heroCollection.isProduct
                  ? `/shop/${heroCollection.slug}`
                  : `/shop/collection/${heroCollection.slug}`
              }
              onClick={() =>
                onClickGtag('click', 'Shop Page', heroCollection.title)
              }
            >
              {heroCollection.isPreOrder ? 'Pre-order now' : 'Shop now'}
            </Button>
          </Flex>
          {isDesktop && !heroCollection.isProduct && (
            <ShirtPromo type="shirt" height="500px" />
          )}
          {/* {isDesktop &&
            heroCollection.isProduct &&
            product &&
            product.image.originalSrc && (
              <img src={product.image.originalSrc} sx={imageSX} alt="" />
            )} */}
        </Grid>
      </HeroLayout>
    )
  }

  const Collections = () => {
    if (!collections) return null

    const sliced = collections.slice(1)

    return (
      <Grid columns={responsive(1, 2)} mt={4} mb={2}>
        {sliced.map((x, i) => {
          return (
            <ImageCard
              key={i}
              thumbnail={{
                url: x.thumbnail?.file?.url,
              }}
              title={x.title}
              button={{
                url: `/shop/collection/${x.slug}`,
                label: 'Shop',
              }}
              height={421}
              shop
              clickTracking={{
                category: 'Shop Page',
                label: x.title,
              }}
            />
          )
        })}
      </Grid>
    )
  }

  return (
    <Layout {...props}>
      <MetaData title="Shop" />
      <ShopHero />
      <Wrapper>
        <Inner>
          <Collections />
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default Shop
